import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string
  public readonly projectLink?: string

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string,
    projectLink?: string
  ) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
    this.projectLink = projectLink
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  // [ChainId.ETHEREUM]: new Token(
  //   ChainId.ETHEREUM,
  //   '0x071cFeF5ce632710ff7Cf0f228A2e6AB0F16eA4e',
  //   18,
  //   'WETH',
  //   'Wrapped ETH',
  //   'https://ethereum.org/en'
  // ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0xFcecf60Fd3954262a80a4460d0389010B8649f00',
    18,
    'WETH',
    'Wrapped ETH',
    'https://ethereum.org/en'
  ),
  // [ChainId.BSC]: new Token(
  //   ChainId.BSC,
  //   '0x071cFeF5ce632710ff7Cf0f228A2e6AB0F16eA4e',
  //   18,
  //   'WETH',
  //   'Wrapped ETH',
  //   'https://www.binance.org'
  // ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeDC899e05f8709C56A744eC52Da06B609fda9cEC',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.org'
  ),
  // [ChainId.POLYGON]: new Token(
  //   ChainId.POLYGON,
  //   '0x071cFeF5ce632710ff7Cf0f228A2e6AB0F16eA4e',
  //   18,
  //   'WMATIC',
  //   'Wrapped MATIC',
  //   'https://polygon.technology'
  // ),
  [ChainId.AMOY]: new Token(
    ChainId.AMOY,
    '0x22640cE04689a0B7ca9A8310c1cAd4C27102678f',
    18,
    'WMATIC',
    'Wrapped MATIC',
    'https://polygon.technology'
  )
}
