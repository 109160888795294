import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | number | string

export enum ChainId {
  // ETHEREUM = 1,
  SEPOLIA = 11155111,
  // BSC = 56,
  BSC_TESTNET = 97,
  // POLYGON = 137,
  AMOY = 80002
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS = '0x685293B37A1176AcD559CeFAA30002dAD837aDD0'

export const FACTORY_ADDRESS_MAP = {
  // [ChainId.ETHEREUM]: FACTORY_ADDRESS,
  [ChainId.SEPOLIA]: '0x685293B37A1176AcD559CeFAA30002dAD837aDD0',
  // [ChainId.BSC]: FACTORY_ADDRESS,
  [ChainId.BSC_TESTNET]: '0x41E99cdf5d3423072a6e419F6AA0efbfdA9162BE',
  // [ChainId.POLYGON]: FACTORY_ADDRESS,
  [ChainId.AMOY]: '0x259EB91022c05fB94D51b64B2c769a942a9351A4'
}

export const INIT_CODE_HASH = '0x7fdda20b953b9ac549e818b7af4b697f756fded7b1c6e2e42084b08388901a32'

export const INIT_CODE_HASH_MAP = {
  // [ChainId.ETHEREUM]: INIT_CODE_HASH,
  [ChainId.SEPOLIA]: INIT_CODE_HASH,
  // [ChainId.BSC]: INIT_CODE_HASH,
  [ChainId.BSC_TESTNET]: INIT_CODE_HASH,
  // [ChainId.POLYGON]: INIT_CODE_HASH,
  [ChainId.AMOY]: INIT_CODE_HASH
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const FEES_NUMERATOR = JSBI.BigInt(9975)
export const FEES_DENOMINATOR = JSBI.BigInt(10000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
